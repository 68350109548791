import { Box } from "@mui/system";
import Header from "../../components/Header";

const Content = () => {
    return(
        <Box m="20px">
            <Header title="Content" subtitle="New content" />
        </Box>
    );
};

export default Content;