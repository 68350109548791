import { Box } from "@mui/system";
import Header from "../../components/Header";

const Contact = () => {
    return (
        <Box m="20px">
            <Header title="CONTACT" subtitle="Contact us" />
        </Box>
    );
};

export default Contact;