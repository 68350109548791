import { useState, React } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import LaptopOutlinedIcon from '@mui/icons-material/LaptopOutlined';
import EngineeringOutlinedIcon from '@mui/icons-material/EngineeringOutlined';
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem active={selected === title} style={{ color: colors.gray[100],}} onClick={() => setSelected(title)} icon={icon} >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");

  return (
    <Box sx={{                                                                             /*  Proprietati ale sidebar-ului   */
        "& .pro-sidebar-inner": { background: `${colors.primary[400]} !important`,},
        "& .pro-icon-wrapper": { backgroundColor: "transparent !important",},
        "& .pro-inner-item": { padding: "5px 35px 5px 10px !important",},
        "& .pro-inner-item:hover": { color: "#868dfb !important",},                        /*  Culoarea item-ului cand mouse-ul este pe el  */
        "& .pro-menu-item.active": {color: "#6870fa !important",},}}>                     {/*  Culoarea item-ului cand este selectat/activ  */} 

      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* __________  Logo and Menu Icon _________ */}
          <MenuItem onClick={() => setIsCollapsed(!isCollapsed)} icon={isCollapsed ? <MenuOutlinedIcon /> : undefined} style={{ margin: "10px 0 15px 0", color: colors.gray[100],}}>
            {!isCollapsed && (
              <Box display="flex" justifyContent="space-between" alignItems="center" ml="15px">
                <Box>
                  <Typography variant="h4" color={colors.gray[100]}>Be Arrogant</Typography>
                  <Typography variant="h5" color={colors.gray[100]}>with Knowledge</Typography>
                </Box>
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}><MenuOutlinedIcon /></IconButton>
              </Box>
            )}
          </MenuItem>
          {/* __________   Profile  _________ */}
          {!isCollapsed && (
            <Box mb="25px">
              <Box display="flex" justifyContent="center" alignItems="center">
                <img alt="profile-user" width="100px" height="100px" src={`../../assets/profil.png`} style={{ cursor: "pointer", borderRadius: "50%" }} />
              </Box>
              <Box textAlign="center">
                <Typography variant="h3" color={colors.gray[100]} fontWeight="bold" sx={{ m: "10px 0 0 0" }}>Alin Gheorghe</Typography>
                <Typography variant="h5" color={colors.greenAccent[500]}>Developer Admin</Typography>
              </Box>
            </Box>
          )}
          {/* __________   Menu Items  _________ */}
          <Box paddingLeft={isCollapsed ? undefined : "10%"}>
            <Item title="Home" to="/" icon={<HomeOutlinedIcon />} selected={selected} setSelected={setSelected} />

            <Typography variant="h6" color={colors.gray[300]} sx={{ m: "15px 0 5px 20px" }}>ABOUT</Typography>
            <Item title="Profile" to="/profile" icon={<PersonOutlinedIcon />} selected={selected} setSelected={setSelected} />
            <Item title="Content" to="/content" icon={<ReceiptOutlinedIcon />} selected={selected} setSelected={setSelected} />

            <Typography variant="h6" color={colors.gray[300]} sx={{ m: "15px 0 5px 20px" }}>GENERAL</Typography>
            <Item title="Projects" to="/projects" icon={<AccountTreeOutlinedIcon />} selected={selected} setSelected={setSelected} />
            <Item title="Management" to="/management" icon={<AssignmentOutlinedIcon />} selected={selected} setSelected={setSelected} />
            <Item title="Development" to="/development" icon={<LaptopOutlinedIcon />} selected={selected} setSelected={setSelected} />
            <Item title="Engineering" to="/engineering" icon={<EngineeringOutlinedIcon />} selected={selected} setSelected={setSelected} />

            <Typography variant="h6" color={colors.gray[300]} sx={{ m: "15px 0 5px 20px" }}>ACCOUNT</Typography>
            <Item title="Contact" to="/contact" icon={<ContactsOutlinedIcon />} selected={selected} setSelected={setSelected} />
            <Item title="Settings" to="/settings" icon={<SettingsOutlinedIcon />} elected={selected} setSelected={setSelected} />
            <Item title="Help" to="/help" icon={<HelpOutlineOutlinedIcon />} selected={selected} setSelected={setSelected} />
    
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;