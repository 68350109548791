import { Box } from "@mui/system";
import Header from "../../components/Header";

const Settings = () => {
    return(
        <Box m="20px">
            <Header title="SETTINGS" subtitle="Profile settings" />
        </Box>
    );
};

export default Settings;