import { Box } from "@mui/system";
import Header from "../../components/Header";

const Management = () => {
    return(
        <Box m="20px">
            <Header title="MANAGEMENT" subtitle="Management role" />
        </Box>
    );
};

export default Management;