import { Box } from "@mui/system";
import Header from "../../components/Header";

const Help = () => {
    return(
        <Box m="20px">
            <Header title="HELP" subtitle="Find all what you need" />
        </Box>
    );
};

export default Help;