import { Box } from "@mui/system";
import Header from "../../components/Header";

const Profile = () => {
    return(
        <Box m="20px">
            <Header title="PROFILE" subtitle="Profile information" />
        </Box>
    );
};

export default Profile;