import { Box } from "@mui/system";
import Header from "../../components/Header";

const Development = () => {
    return(
        <Box m="20px">
            <Header title="DEVELOPMENT" subtitle="Development projects" />
        </Box>
    );
};

export default Development;