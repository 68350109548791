import { Box } from "@mui/system";
import Header from "../../components/Header";

const Projects = () => {
    return(
        <Box m="20px">
            <Header title="PROJECTS" subtitle="Projects description" />
        </Box>
    );
};

export default Projects;