import { Box } from "@mui/system";
import Header from "../../components/Header";

const Engineering = () => {
    return(
        <Box m="20px">
            <Header title="ENGINEERING" subtitle="Engineering projects" />
        </Box>
    );
};

export default Engineering;