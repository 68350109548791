import { useState } from "react";
import { Routes, Route } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./scenes/dashboard";
import Profile from "./scenes/profile";
import Content from "./scenes/content";
import Projects from "./scenes/projects";
import Management from "./scenes/management";
import Development from "./scenes/development";
import Engineering from "./scenes/engineering";
import Contact from "./scenes/contact";
import Settings from "./scenes/settings";
import Help from "./scenes/help";

function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <Sidebar isSidebar={isSidebar} />
          <main className="content">
            <Topbar setIsSidebar={setIsSidebar} />
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/content" element={<Content />} />
              <Route path="/projects" element={<Projects />} />
              <Route path="/management" element={<Management />} />
              <Route path="/development" element={<Development />} />
              <Route path="/engineering" element={<Engineering />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/help" element={<Help />} />
            </Routes>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;